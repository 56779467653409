<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <polygon
        style="fill:#82DCC7;"
        points="255.999,450.013 0,191.905 97.783,61.992 414.217,61.992 512,191.905 	"
      />
      <polygon
        style="fill:#82DCC7;"
        points="511.999,191.909 255.999,450.013 389.005,191.909 	"
      />
    </g>
    <g>
      <polygon
        style="fill:#95E5D0;"
        points="255.999,191.909 255.999,450.013 389.005,191.909 	"
      />
      <polygon
        style="fill:#95E5D0;"
        points="97.783,61.987 122.994,191.909 0,191.909 	"
      />
    </g>
    <polygon
      style="fill:#A5EAD6;"
      points="122.994,191.909 255.999,191.909 255.999,61.987 "
    />
    <g>
      <polygon
        style="fill:#74CBB4;"
        points="414.216,61.987 511.999,191.909 389.005,191.909 	"
      />
      <polygon
        style="fill:#74CBB4;"
        points="122.994,191.909 255.999,450.013 255.999,191.909 	"
      />
      <polygon
        style="fill:#74CBB4;"
        points="255.999,61.987 122.994,191.909 97.783,61.987 	"
      />
    </g>
    <polygon
      style="fill:#A5EAD6;"
      points="255.999,61.987 389.005,191.909 414.216,61.987 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
